<template>
    <div>
        <v-icon class="text-black"
                color="#000"
                size="20"
                @click="back">
            mdi-arrow-left
        </v-icon>
        <span class="text-black font-weight-bold pl-5 size">
            {{ $t('View Finance') }}
        </span>
        <v-divider color="white"
                   class="mt-5 mb-7"></v-divider>
        <div>
            <v-card>
                <v-card-text>
                    <div class="d-flex justify-space-between align-center pb-2">
                        <p style="font-size: 20px;"
                           class="font-weight-semibold text-green color">
                            {{ $t('User Details') }}
                        </p>
                    </div>
                    <v-row dense
                           class="mt-n4">
                        <v-col v-for="item in items"
                               :key="item.id"
                               cols="12"
                               lg="2"
                               md="2"
                               sm="3">
                            <div class="color text-green font-weight-regular text-caption">
                                {{ $t(item.id) }}
                            </div>
                            <p class=" mt-1 text-black text-caption font-weight-bold">
                                {{ item.text }}
                            </p>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>

            <v-card class="mt-7">
                <v-card-text>
                    <div class="d-flex justify-space-between align-center pb-2">
                        <p style="font-size: 20px;"
                           class="font-weight-semibold text-green color">
                            {{ $t('Booking Details') }}
                        </p>

                        <v-btn 
                               id="invoice"
                               depressed
                               color="#00B2A9"
                               class="text-white text-capitalize rounded-lg px-5 font-weight-thin owner-info-btn border-card"
                               @click="print">
                            <v-icon size="20"
                                    class="mr-2">
                                mdi-printer-outline
                            </v-icon>
                            {{ $t('Print Invoice') }}
                        </v-btn>
                    </div>
                </v-card-text>
                <v-row dense
                       class="mt-n14">
                    <v-col cols="12">
                        <v-card-title class="text-body-2 font-weight-bold">
                            <span class="text-black">{{ this.boatName }}</span>
                            <!-- <v-btn
                v-if="boatDetail.status != 'cancelled'"
                depressed
                x-small
                color="#C9F4D4"
                class="ml-5 font-color px-5 my-2 text-capitalize btn-text  border-card"
              >
                {{ boatDetail.status == 'in_progress' ? 'In Progress' : boatDetail.status }}
              </v-btn> -->
                            <v-chip x-small
                                    :color="resolveStatusVariant(boatDetail.status).variant"
                                    :class="`mx-3 v-chip-light-bg ${resolveStatusVariant(boatDetail.status).variant}--text font-weight-thin text-caption`">
                                {{ $t(resolveStatusVariant(boatDetail.status).name) }}
                            </v-chip>



                        </v-card-title>
                        <v-card-subtitle class="mt-n5">
                            <v-icon size="15">
                                mdi-map-marker-radius-outline
                            </v-icon>
                            <span class="ml-2 text-caption">{{ city ? city.name + ' | ' : '' }} {{ country ? country.name :
                                ''
                            }}</span>
                            <div class="mt-1 mb-4">
                                <v-icon size="15">
                                    mdi-calendar-month-outline
                                </v-icon>
                                <span class="ml-2 text-caption">{{ created_at }}</span>
                            </div>
                            <v-divider></v-divider>
                        </v-card-subtitle>
                        <v-card-subtitle class="mt-n2">
                            <div class="text-green font-weight-medium text-caption mb-1">
                                {{ $t('Transaction ID') }}
                                <span class="float-right text-black text-caption font-weight-bold">{{ transactionId
                                }}</span>
                            </div>
                            <div class="text-green font-weight-medium text-caption mb-1">
                                {{ $t('Booking ID') }}
                                <span class="float-right text-black text-caption font-weight-bold">{{ boatDetail.id
                                }}</span>
                            </div>
                            <div v-if="boatPackages.length > 0">
                                <div v-for="(item, i) in boatPackages"
                                     :key="item"
                                     class="text-green font-weight-medium text-caption mb-1">
                                    {{ item.name
                                    }}
                                    <span class="float-right text-black text-caption font-weight-bold">{{
                                        item.price }}
                                        SAR</span>
                                </div>
                            </div>

                            <div class="text-green font-weight-medium text-caption mb-1">
                                {{ $t('Payment Method') }}
                                <span class="float-right text-black text-caption font-weight-bold">{{
                                    boatDetail.payment_method }}
                                </span>
                            </div>
                            <div class="text-green font-weight-medium text-caption mb-1">
                                {{ $t('Price of Booking') }}
                                <span class="float-right text-black text-caption font-weight-bold">
                                    {{ boatDetail.booking_price }} SAR ({{ (totalHours) }} Hours)</span>
                            </div>

                            <v-divider class="mt-2"></v-divider>
                        </v-card-subtitle>

                        <v-card-subtitle class="mt-n3">
                            <div class="color text-green font-weight-medium text-caption mb-1">
                                {{ $t('Order Amount') }}
                                <span style="float:right;"
                                      class="text-black text-caption font-weight-bold">{{
                                          boatDetail.order_amount }}
                                    SAR</span>
                            </div>

                            <div class="color text-green font-weight-medium text-caption mb-1">
                                {{ $t('MyBoat Commission') }}
                                <span style="float:right;"
                                      class="color text-black text-caption font-weight-bold">
                                    {{ myBoatCommision }} SAR ({{ (boatDetail.boat_commission) }}%)</span>
                            </div>
                            <div class="color text-green font-weight-medium text-caption">
                                {{ $t('VAT') }}
                                <span style="float:right;"
                                      class="text-black text-caption font-weight-bold">{{
                                          boatDetail.booking_vat
                                      }}%</span>
                            </div>
                            <v-divider class="mt-1"></v-divider>
                        </v-card-subtitle>

                        <v-card-subtitle>
                            <div class="color text-green font-weight-bold text-caption mt-n2 mb-1">
                                {{ $t('Total Amount') }}
                                <span style="float:right;"
                                      class="color text-black text-caption font-weight-bold">{{
                                          boatDetail.total_amount }} SAR</span>
                            </div>
                        </v-card-subtitle>
                    </v-col>
                </v-row>
            </v-card>
        </div>

        <div id="my-printable-content"
             style="display: none;">
            <h1 style="font-family: 'Inter', sans-serif;">{{ $t('Finance Details') }}</h1>
            <h2 style="font-family: 'Inter', sans-serif;">{{ $t('User Details') }}</h2>

            <table style=" border-collapse: collapse;">
                <tr>
                    <span v-for="item in items"
                          :key="item.id">
                        <th style="text-align:left;padding:5px 5px 0;font-family: 'Inter', sans-serif;">
                            {{ $t(item.id) }}
                        </th>
                    </span>
                </tr>
                <tr>
                    <span v-for="item in items"
                          :key="item.id">
                        <td style="text-align:left;padding:5px 5px 0;font-family: 'Inter', sans-serif;">
                            {{ item.text }}
                        </td>
                    </span>
                </tr>
            </table>

            <h2 style="font-family: 'Inter', sans-serif;">{{ $t('Booking Details') }}</h2>
            <div style="display: flex;justify-content: space-between;">
                <span style="font-family: 'Inter', sans-serif;font-weight: bold;">{{ this.boatName }}</span>
                <span style="font-family: 'Inter', sans-serif;">
                    Status: {{ boatDetail.status == 'in_progress' ? 'In Progress' : boatDetail.status }}
                </span>
            </div>

            <p style="font-family: 'Inter', sans-serif;">
                {{ city ? city.name : '' }} | {{ country ? country.name : '' }}

                <!-- {{ city ? city.name : '' }} - {{ country ? country.name : '' }} -->
            </p>
            <p style="font-family: 'Inter', sans-serif;">
                {{ created_at }}

                <!-- {{ created_at }} - {{ from_time }} -> {{ to_time }} -->
            </p>

            <hr>

            <div style="display: flex;justify-content: space-between;"
                 v-for="item in items1"
                 :key="text">
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">{{ $t(item.text)
                }}</span>
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                {{ item.text == 'Price of Booking' ? boatDetail.booking_price +' '+'SAR' +' '+`(${totalHours} Hours)` : item.id }}</span>
            </div>

            <hr>

            <div style="display: flex;justify-content: space-between;">
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">{{ $t('Order Amount')
                }}</span>
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                    {{ boatDetail.order_amount }} SAR

                    <!-- {{ boatDetail.order_amount.toLocaleString() }} SAR -->
                </span>
            </div>
            <div style="display: flex;justify-content: space-between;">
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">{{ $t('VAT') }}</span>
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                    {{ boatDetail.booking_vat }}%
                    <!-- {{ boatDetail.booking_vat }}% -->
                </span>
            </div>

            <hr>

            <div style="display: flex;justify-content: space-between;">
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">{{ $t('Total Amount')
                }}</span>
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                    {{ boatDetail.total_amount }} SAR
                    <!-- {{ boatDetail.total_amount }} -->
                </span>
            </div>

            <hr>

            <div style="display: flex;justify-content: space-between;">
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                    {{ $t('MyBoat Commission') }}</span>
                <span style="padding-top: 5px;font-weight: bold;font-family: 'Inter', sans-serif;">
                    {{ myBoatCommision }} SAR ({{ (boatDetail.boat_commission) }}%)
                    <!-- {{ boatDetail.boat_commission.toFixed(2) }} -->
                </span>
            </div>

        </div>
    </div>
</template>

<script>
import router from '@/router'
import financeServices from '@/services/finance-services'
import store from '@/store'
import moment from 'moment'
import printJS from 'print-js'
import createNumberMask from 'text-mask-addons/dist/createNumberMask'

const currencyMask = createNumberMask({
    prefix: '',
    suffix: '00',
    allowDecimal: true,
    decimalSymbol: '.',
    decimalLimit: 2,
    requireDecimal: true,
    includeThousandsSeparator: true,
    thousandsSeparatorSymbol: ',',
    allowNegative: false,
})

export default {
    data()
    {
        return {
            // showCard: true,
            items: [
            ],
            boatName: '',
            created_at: '',
            from_time: '',
            to_time: '',
            boatDetail: {},
            currencyMask,
            boatPackages: [],
            city: '',
            country: '',
            totalHours: '',
            transactionId: '',
            myBoatCommision: '',
            items1: [
            ],
        }
    },

    mounted()
    {
        this.id = router.currentRoute.params.id
        // console.log(this.id)
        if (this.id)
        {
            store.set('accounts/loader', true)
            financeServices.viewFinanceList(this.id).then(resp =>
            {
                if (resp.statusCode == 200)
                {
                    store.set('accounts/loader', false)
                    this.boatDetail = resp.data.bookings[0]
                    if (this.boatDetail.boat_commission != null && this.boatDetail.boat_commission > 0)
                    {
                        this.myBoatCommision = (this.boatDetail.total_amount / 100) * this.boatDetail.boat_commission
                        this.myBoatCommision = this.myBoatCommision.toFixed(2)
                    }
                    this.city = resp.data.bookings[0].city
                    this.country = resp.data.bookings[0].city?.country
                    this.boatName = resp.data.bookings[0].boat.boat_name

                    this.created_at = moment(new Date(resp.data.bookings[0].on_date)).format('DD/MM/yyyy')

                    // this.from_time = resp.data.bookings[0].from_time
                    // this.to_time = resp.data.bookings[0].to_time

                    if (resp.data.bookings[0].from_time)
                    {
                        this.created_at = `${this.created_at} | ${resp.data.bookings[0].from_time}`
                    }
                    if (resp.data.bookings[0].to_time)
                    {
                        this.created_at = `${this.created_at} - ${resp.data.bookings[0].to_time}`
                    }

                    /// get hours//////
                    if (resp.data.bookings[0].from_date_time && resp.data.bookings[0].to_date_time)
                    {
                        const fromDate = new Date(resp.data.bookings[0].from_date_time)
                        const toDate = new Date(resp.data.bookings[0].to_date_time)

                        const timeDiff = toDate.getTime() - fromDate.getTime()
                        const hours = Math.floor(timeDiff / (1000 * 60 * 60))

                        this.totalHours = hours
                    }

                    this.transactionId = this.boatDetail?.invoice_id ? this.boatDetail?.invoice_id : `${'' + '|'}${this.boatDetail?.invoice_reference}` ? this.boatDetail?.invoice_reference : ''
                    this.items1 = [
                        { text: 'Transaction ID', id: this.transactionId },
                        { text: 'Booking ID', id: this.boatDetail?.id },
                        { text: 'Payment Method', id: this.boatDetail?.payment_method },
                        { text: 'Price of Booking', id: `${this.boatDetail?.booking_price} ` + 'SAR' + ' ' + `${(this.totalHours)} Hours` },
                    ]
                    if (resp.data.bookings[0].booking_packages)
                    {
                        this.boatPackages = resp.data.bookings[0].booking_packages
                        // console.log('PACKAGESSSSSSSSS', this.boatPackages)
                    }
                    if (this.boatPackages)
                    {
                        this.boatPackages.forEach(element =>
                        {
                            this.items1.push({
                                text: element.name,
                                id: `${element.price} ` + 'SAR',
                            })
                        })
                    }
                    if (resp.data.bookings[0]?.user)
                    {
                        this.items = [
                            { id: 'User ID', text: resp.data.bookings[0]?.user.id },
                            { id: 'Username', text: `${resp.data.bookings[0]?.user.first_name} ${resp.data.bookings[0]?.user.last_name}` },
                            { id: 'Mobile No', text: resp.data.bookings[0]?.user.phone },
                            { id: 'Email Address', text: resp.data.bookings[0]?.user.email },
                            { id: 'Booking Date', text: resp.data.bookings[0].on_date },
                            { id: 'Requested Date', text: moment(new Date(resp.data.bookings[0].created_at)).format('DD/MM/yyyy') },
                        ]
                    }
                }
            })
        }
    },
    methods: {
        resolveStatusVariant(status)
        {
            if (status === 'pending') return { variant: 'success', name: 'Pending' }
            if (status === 'confirmed') return { variant: 'primary', name: 'Confirmed' }
            if (status === 'cancelled') return { variant: 'error', name: 'Cancelled' }
            if (status === 'in_progress') return { variant: 'warning', name: 'In Progress' }
            if (status === 'completed') return { variant: 'success', name: 'Completed' }
            // if (status === 'pending_boat_inspection') return { variant: 'info', name: 'Pending Inspection' }

            return { variant: 'secondary', name: 'Not Available' }
        },
        print()
        {
            document.getElementById('my-printable-content').style.display = 'block';
            const printContent = document.getElementById("my-printable-content")
            printJS({
                printable: printContent.innerHTML,
                type: 'raw-html'
            });
            document.getElementById('my-printable-content').style.display = 'none';
        },

        back()
        {
            router.push('/finance/list')
        },
    },
}
</script>

<style scoped>
.font-color {
    color: #464646;
}

.text-white {
    color: #fff;
}

.size {
    font-size: 20px;
}

.color {
    color: #00B2A9;
}

.btn-text {
    color: #139536;
}

.top {
    margin-top: 200px;
}

::v-deep .v-chip.v-size--x-small {
    border-radius: 3px !important;
    height: 24px !important;
}
</style>
